import TaskClientList from "@twcategory-api/client/lib/List/TaskClientList";
import TaskClientLastChanges from "@twcategory-api/client/lib/LastChanges/TaskClientLastChanges";
import { ConfigHost, Hostname, Port, SubDomain } from "@twcategory-api/client/lib/TaskClientBase";
import { ListOrder, ListSelection } from "@twcategory-api/common/lib/Category/CategoryList";
import Constant from "@twcategory-api/common/lib/WebApi/Constant";
import ResponseList from "@twcategory-api/common/lib/WebApi/List/ResponseList";
import ResponseError from "@twcategory-api/common/lib/WebApi/ResponseError";
import ResponseLastChanges from "@twcategory-api/common/lib/WebApi/LastChanges/ResponseLastChanges";
import { ChangeFlags, LastChangesRevision, LastChangesSelection } from "@twcategory-api/common/lib/Category/LastChanges";

enum ApiHost
{
	None,
	Document,
	Local,
	TwcategoryLocal,
	FinalLocal,		// 'xenoncat.local', 'streamcategory.local', or ...
}

class App
{
	// ------------------------------
	//  Consts
	// ------------------------------

	protected static readonly	ID_HOST_API_DOCUMENT					: string		= "host-api-document";
	protected static readonly	ID_HOST_API_LOCALHOST					: string		= "host-api-localhost";
	protected static readonly	ID_HOST_API_TWCATEGORY_LOCAL			: string		= "host-api-twcategory-local";
	protected static readonly	ID_HOST_API_FINAL_LOCAL					: string		= "host-api-final-local";

	protected static readonly	ID_LIST_SEND							: string		= "list-send";
	protected static readonly	ID_LIST_TEXT							: string		= "list-text";
	protected static readonly	ID_LIST_PAGE							: string		= "list-page";
	protected static readonly	ID_LIST_SEARCH							: string		= "list-search";
	protected static readonly	ID_LIST_ORDER							: string		= "list-order";
	protected static readonly	ID_LIST_REVERSE_ORDER					: string		= "list-reverse-order";
	protected static readonly	ID_LIST_SELECTION						: string		= "list-selection";
	protected static readonly	ID_LIST_MISSING_BOXART					: string		= "list-missing-boxart";

	protected static readonly	ID_LAST_CHANGES_SEND					: string		= "last-changes-send";
	protected static readonly	ID_LAST_CHANGES_TEXT					: string		= "last-changes-text";
	protected static readonly	ID_LAST_CHANGES_PAGE					: string		= "last-changes-page";
	protected static readonly	ID_LAST_CHANGES_SELECTION				: string		= "last-changes-selection";
	protected static readonly	ID_LAST_CHANGES_REVISION				: string		= "last-changes-revision";
	protected static readonly	ID_LAST_CHANGES_FLAGS					: string		= "last-changes-flags";
	protected static readonly	ID_LAST_CHANGES_DUPLICATES				: string		= "last-changes-duplicates";

	protected static readonly	VALUE_LIST_ORDER_NONE					: string		= "";
	protected static readonly	VALUE_LIST_ORDER_POPULARITY				: string		= "popularity";
	protected static readonly	VALUE_LIST_ORDER_ALPHABETIC				: string		= "alphabetic";
	protected static readonly	VALUE_LIST_ORDER_LAST_BOXART			: string		= "last-boxart";
	protected static readonly	VALUE_LIST_ORDER_LAST_NAME				: string		= "last-name";
	protected static readonly	VALUE_LIST_ORDER_LAST_DESCRIPTION		: string		= "last-description";
	protected static readonly	VALUE_LIST_ORDER_LAST_TAGS				: string		= "last-tags";

	protected static readonly	VALUE_LIST_SELECTION_NONE				: string		= "";
	protected static readonly	VALUE_LIST_SELECTION_ALL				: string		= "all";
	protected static readonly	VALUE_LIST_SELECTION_POPULAR			: string		= "popular";
	protected static readonly	VALUE_LIST_SELECTION_NEW				: string		= "new";
	protected static readonly	VALUE_LIST_SELECTION_DELETED			: string		= "deleted";

	protected static readonly	VALUE_LAST_CHANGES_SELECTION_NONE		: string		= "";
	protected static readonly	VALUE_LAST_CHANGES_SELECTION_ALL		: string		= "all";
	protected static readonly	VALUE_LAST_CHANGES_SELECTION_POPULAR	: string		= "popular";
	protected static readonly	VALUE_LAST_CHANGES_SELECTION_NEW		: string		= "new";

	protected static readonly	VALUE_LAST_CHANGES_REVISION_NONE		: string		= "";
	protected static readonly	VALUE_LAST_CHANGES_REVISION_ALL			: string		= "all";
	protected static readonly	VALUE_LAST_CHANGES_REVISION_ADDED		: string		= "added";
	protected static readonly	VALUE_LAST_CHANGES_REVISION_CHANGED		: string		= "changed";

	protected static readonly	VALUE_LAST_CHANGES_FLAGS_NONE			: string		= "";
	protected static readonly	VALUE_LAST_CHANGES_FLAGS_NAME			: string		= "name";
	protected static readonly	VALUE_LAST_CHANGES_FLAGS_BOXART			: string		= "boxart";
	protected static readonly	VALUE_LAST_CHANGES_FLAGS_DESCRIPTION	: string		= "description";
	protected static readonly	VALUE_LAST_CHANGES_FLAGS_TAGS			: string		= "tags";
	protected static readonly	VALUE_LAST_CHANGES_FLAGS_FIRST_SCAN		: string		= "first-scan";
	protected static readonly	VALUE_LAST_CHANGES_FLAGS_DELETED		: string		= "deleted";

	protected static readonly	MAP_VALUE_LIST_ORDERS	= new Map<string, ListOrder | undefined>(
	[
		[	this.VALUE_LIST_ORDER_NONE				,	undefined					],
		[	this.VALUE_LIST_ORDER_POPULARITY		,	ListOrder.Popularity		],
		[	this.VALUE_LIST_ORDER_ALPHABETIC		,	ListOrder.Alphabetic		],
		[	this.VALUE_LIST_ORDER_LAST_BOXART		,	ListOrder.LastBoxart		],
		[	this.VALUE_LIST_ORDER_LAST_NAME			,	ListOrder.LastName			],
		[	this.VALUE_LIST_ORDER_LAST_DESCRIPTION	,	ListOrder.LastDescription	],
		[	this.VALUE_LIST_ORDER_LAST_TAGS			,	ListOrder.LastTags			],
	]);

	protected static readonly	MAP_VALUE_LIST_SELECTIONS	= new Map<string, ListSelection | undefined>(
	[
		[	this.VALUE_LIST_SELECTION_NONE		,	undefined					],
		[	this.VALUE_LIST_SELECTION_ALL		,	ListSelection.All			],
		[	this.VALUE_LIST_SELECTION_POPULAR	,	ListSelection.Popular		],
		[	this.VALUE_LIST_SELECTION_NEW		,	ListSelection.New			],
		[	this.VALUE_LIST_SELECTION_DELETED	,	ListSelection.Deleted		],
	]);

	protected static readonly	MAP_VALUE_LAST_CHANGES_SELECTIONS	= new Map<string, LastChangesSelection | undefined>(
	[
		[	this.VALUE_LAST_CHANGES_SELECTION_NONE		,	undefined						],
		[	this.VALUE_LAST_CHANGES_SELECTION_ALL		,	LastChangesSelection.All		],
		[	this.VALUE_LAST_CHANGES_SELECTION_POPULAR	,	LastChangesSelection.Popular	],
		[	this.VALUE_LAST_CHANGES_SELECTION_NEW		,	LastChangesSelection.New		],
	]);

	protected static readonly	MAP_VALUE_LAST_CHANGES_REVISIONS	= new Map<string, LastChangesRevision | undefined>(
	[
		[	this.VALUE_LAST_CHANGES_REVISION_NONE		,	undefined						],
		[	this.VALUE_LAST_CHANGES_REVISION_ALL		,	LastChangesRevision.All			],
		[	this.VALUE_LAST_CHANGES_REVISION_ADDED		,	LastChangesRevision.Added		],
		[	this.VALUE_LAST_CHANGES_REVISION_CHANGED	,	LastChangesRevision.Changed		],
	]);

	protected static readonly	MAP_VALUE_LAST_CHANGES_FLAGS	= new Map<string, ChangeFlags>(
	[
		[	this.VALUE_LAST_CHANGES_FLAGS_NONE			,	ChangeFlags.None				],
		[	this.VALUE_LAST_CHANGES_FLAGS_NAME			,	ChangeFlags.Name				],
		[	this.VALUE_LAST_CHANGES_FLAGS_BOXART		,	ChangeFlags.Boxart				],
		[	this.VALUE_LAST_CHANGES_FLAGS_DESCRIPTION	,	ChangeFlags.Description			],
		[	this.VALUE_LAST_CHANGES_FLAGS_TAGS			,	ChangeFlags.Tags				],
		[	this.VALUE_LAST_CHANGES_FLAGS_FIRST_SCAN	,	ChangeFlags.FirstScan			],
		[	this.VALUE_LAST_CHANGES_FLAGS_DELETED		,	ChangeFlags.Deleted				],
	]);

	// ------------------------------
	//  Data members
	// ------------------------------

	protected radioHostApiDocument!			: HTMLInputElement | null;
	protected radioHostApiLocal!			: HTMLInputElement | null;
	protected radioHostApiTwcategoryLocal!	: HTMLInputElement | null;
	protected radioHostApiFinalLocal!		: HTMLInputElement | null;
	protected buttonListSend!				: HTMLButtonElement | null;
	protected textListResult!				: HTMLPreElement | null;
	protected inputListPage!				: HTMLInputElement | null;
	protected inputListSearch!				: HTMLInputElement | null;
	protected selectListOrder!				: HTMLSelectElement | null;
	protected inputListReverseOrder!		: HTMLInputElement | null;
	protected selectListSelection!			: HTMLSelectElement | null;
	protected inputListMissingBoxart!		: HTMLInputElement | null;
	protected buttonLastChangesSend!		: HTMLButtonElement | null;
	protected textLastChangesResult!		: HTMLPreElement | null;
	protected inputLastChangesPage!			: HTMLInputElement | null;
	protected selectLastChangesSelection!	: HTMLSelectElement | null;
	protected selectLastChangesRevision!	: HTMLSelectElement | null;
	protected selectLastChangesFlags!		: HTMLSelectElement | null;
	protected inputLastChangesDuplicates!	: HTMLInputElement | null;

	protected lastSelectedApiHost			: ApiHost						= ApiHost.None;
	protected lastApiConfigHost				: ConfigHost					= { };

	// ------------------------------
	//  Constructor(s)
	// ------------------------------

	public constructor()
	{
		this.initHtmlElements();
	}

	// ------------------------------
	//  Methods
	// ------------------------------

	// ------------------------------
	//  Internal methods
	// ------------------------------

	protected initHtmlElements() : void
	{
		this.radioHostApiDocument 			= document.getElementById(App.ID_HOST_API_DOCUMENT			) as HTMLInputElement;
		this.radioHostApiLocal 				= document.getElementById(App.ID_HOST_API_LOCALHOST			) as HTMLInputElement;
		this.radioHostApiTwcategoryLocal 	= document.getElementById(App.ID_HOST_API_TWCATEGORY_LOCAL	) as HTMLInputElement;
		this.radioHostApiFinalLocal 		= document.getElementById(App.ID_HOST_API_FINAL_LOCAL		) as HTMLInputElement;

		this.buttonListSend 				= document.getElementById(App.ID_LIST_SEND					) as HTMLButtonElement;
		this.textListResult 				= document.getElementById(App.ID_LIST_TEXT					) as HTMLPreElement;
		this.inputListPage 					= document.getElementById(App.ID_LIST_PAGE					) as HTMLInputElement;
		this.inputListSearch 				= document.getElementById(App.ID_LIST_SEARCH				) as HTMLInputElement;
		this.selectListOrder 				= document.getElementById(App.ID_LIST_ORDER					) as HTMLSelectElement;
		this.inputListReverseOrder 			= document.getElementById(App.ID_LIST_REVERSE_ORDER			) as HTMLInputElement;
		this.selectListSelection			= document.getElementById(App.ID_LIST_SELECTION				) as HTMLSelectElement;
		this.inputListMissingBoxart			= document.getElementById(App.ID_LIST_MISSING_BOXART		) as HTMLInputElement;

		this.buttonLastChangesSend			= document.getElementById(App.ID_LAST_CHANGES_SEND			) as HTMLButtonElement;
		this.textLastChangesResult			= document.getElementById(App.ID_LAST_CHANGES_TEXT			) as HTMLPreElement;
		this.inputLastChangesPage			= document.getElementById(App.ID_LAST_CHANGES_PAGE			) as HTMLInputElement;
		this.selectLastChangesSelection		= document.getElementById(App.ID_LAST_CHANGES_SELECTION		) as HTMLSelectElement;
		this.selectLastChangesRevision		= document.getElementById(App.ID_LAST_CHANGES_REVISION		) as HTMLSelectElement;
		this.selectLastChangesFlags			= document.getElementById(App.ID_LAST_CHANGES_FLAGS			) as HTMLSelectElement;
		this.inputLastChangesDuplicates		= document.getElementById(App.ID_LAST_CHANGES_DUPLICATES	) as HTMLInputElement;

		const _this = this;
		this.buttonListSend?.addEventListener("click", () => _this.onListSend());
		this.buttonLastChangesSend?.addEventListener("click", () => _this.onLastChangesSend());
	}

	protected getApiConfigHost() : ConfigHost
	{
		let configHost = this.lastApiConfigHost;
		const apiHost = this.selectedApiHost();
		if (apiHost != this.lastSelectedApiHost)
		{
			switch (apiHost)
			{
				case ApiHost.Document:
					configHost = { subDomain : SubDomain.Api, hostname : Hostname.CurrentDocument };
					break;
				case ApiHost.Local:
					configHost = { hostname : Hostname.Local, port : Port.Local };
					break;
				case ApiHost.TwcategoryLocal:
					configHost = { subDomain : SubDomain.Api, hostname : Constant.SERVER_HOST_LOCAL2 };
					break;
				case ApiHost.FinalLocal:
					configHost = { subDomain : SubDomain.Api, hostname : Constant.SERVER_HOST_LOCAL3 };
					break;
			}
		//	console.log(configHost);

			this.lastSelectedApiHost = apiHost;
			this.lastApiConfigHost = configHost;
		}
		return configHost;
	}

	protected selectedApiHost() : ApiHost
	{
		let apiHost = ApiHost.None;
		const apiDocument 			= App.valueInputCheck(this.radioHostApiDocument);
		const apiLocal 				= App.valueInputCheck(this.radioHostApiLocal);
		const apiTwcategoryLocal 	= App.valueInputCheck(this.radioHostApiTwcategoryLocal);
		const apiFinalLocal 		= App.valueInputCheck(this.radioHostApiFinalLocal);
		if (apiDocument)
		{
			apiHost = ApiHost.Document;
		}
		else if (apiLocal)
		{
			apiHost = ApiHost.Local;
		}
		else if (apiTwcategoryLocal)
		{
			apiHost = ApiHost.TwcategoryLocal;
		}
		else if (apiFinalLocal)
		{
			apiHost = ApiHost.FinalLocal;
		}
		return apiHost;
	}

	protected onListSend() : void
	{
	//	console.log(this);
		console.log("list");
		console.log(this.valueListPage());
		console.log(this.valueListSearch());
		console.log(this.valueListOrder());
		console.log(this.valueListReverseOrder());
		console.log(this.valueListSelection());
		console.log(this.valueListMissingBoxarts());
		this.setListResult(null);
		const list = new TaskClientList(this.valueListPage(), this.valueListSearch(), this.valueListOrder(),
				this.valueListReverseOrder(), this.valueListSelection(), this.valueListMissingBoxarts())
			.configHost(this.getApiConfigHost())
			.run().then((r : ResponseList | ResponseError) =>
			{
				console.log(r);
				this.setListResult(r);
			});
	}

	protected onLastChangesSend() : void
	{
		console.log("last changes");
		console.log(this.valueLastChangesPage());
		console.log(this.valueLastChangesSelection());
		console.log(this.valueLastChangesRevision());
		console.log(this.valueLastChangesFlags());
		console.log(this.valueLastChangesDuplicates());
		const list = new TaskClientLastChanges(this.valueLastChangesPage(), this.valueLastChangesSelection(),
				this.valueLastChangesRevision(), this.valueLastChangesFlags(), this.valueLastChangesDuplicates())
			.configHost(this.getApiConfigHost())
			.run().then((r : ResponseLastChanges | ResponseError) =>
			{
				console.log(r);
				this.setLastChangesResult(r);
			});
	}

	protected setListResult(o : object | null) : void
	{
		this.displayResult(this.textListResult, o);
	}

	protected setLastChangesResult(o : object | null) : void
	{
		this.displayResult(this.textLastChangesResult, o);
	}

	protected displayResult(pre : HTMLPreElement | null, o : object | null) : void
	{
		if (pre != null)
		{
			pre.innerText = (o != null) ? JSON.stringify(o, null, 1) : " … ";
		}
	}

	protected static valueInputNumber(input : HTMLInputElement | null) : number | undefined
	{
		const value = input?.valueAsNumber;
		return ((value == undefined) || (isNaN(value))) ? undefined : value;

	}

	protected static valueInputText(input : HTMLInputElement | null) : string | undefined
	{
		return input?.value;
	}

	protected static valueInputCheck(input : HTMLInputElement | null) : boolean
	{
		return input?.checked ?? false;
	}

	protected static valueInputSelect<T>(input : HTMLSelectElement | HTMLOptionElement | null, map : Map<string, T>) : T | undefined
	{
		const valueString = input?.value;
		const value = (valueString != undefined) ? map.get(valueString) : undefined;
		return value;

	}

	protected static valueInputSelectMultiple<T>(input : HTMLSelectElement | null, map : Map<string, T>) : T[]
	{
		const values : T[] = [];
		for (const option of input?.options ?? [])
		{
			if (option.selected)
			{
				const value = App.valueInputSelect(option, map);
				if (value != undefined)
				{
					values.push(value);
				}
			}
		}
		return values;
	}

	protected static valueInputSelectMultipleCombine<T>(input : HTMLSelectElement | null, map : Map<string, T>) : T
	{
		const values = this.valueInputSelectMultiple(input, map);
		let valueResult = 0;
		for (const value of values)
		{
			valueResult |= value as number;
		}
		return valueResult as T;
	}

	protected valueListPage() : number | undefined
	{
		return App.valueInputNumber(this.inputListPage);
	}

	protected valueListSearch() : string | undefined
	{
		return this.inputListSearch?.value;
	}

	protected valueListOrder() : ListOrder | undefined
	{
		return App.valueInputSelect(this.selectListOrder, App.MAP_VALUE_LIST_ORDERS);
	}

	protected valueListReverseOrder() : boolean | undefined
	{
		return App.valueInputCheck(this.inputListReverseOrder);
	}

	protected valueListSelection() : ListSelection | undefined
	{
		return App.valueInputSelect(this.selectListSelection, App.MAP_VALUE_LIST_SELECTIONS);
	}

	protected valueListMissingBoxarts() : boolean
	{
		return App.valueInputCheck(this.inputListMissingBoxart);
	}

	protected valueLastChangesPage() : number | undefined
	{
		return App.valueInputNumber(this.inputLastChangesPage);
	}

	protected valueLastChangesSelection() : LastChangesSelection | undefined
	{
		return App.valueInputSelect(this.selectLastChangesSelection, App.MAP_VALUE_LAST_CHANGES_SELECTIONS);
	}

	protected valueLastChangesRevision() : LastChangesRevision | undefined
	{
		return App.valueInputSelect(this.selectLastChangesRevision, App.MAP_VALUE_LAST_CHANGES_REVISIONS);
	}

	protected valueLastChangesFlags() : ChangeFlags
	{
		return App.valueInputSelectMultipleCombine(this.selectLastChangesFlags, App.MAP_VALUE_LAST_CHANGES_FLAGS);
	}

	protected valueLastChangesDuplicates() : boolean | undefined
	{
		return App.valueInputCheck(this.inputLastChangesDuplicates);
	}

}

export default App;
